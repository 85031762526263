<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column" v-html="title"></h3>
      <div class="d-flex">
        <slot></slot>
      </div>
    </div>
    <div class="card-body pt-3 pb-0">
      <div
        v-if="!count"
        class="card-label font-weight-bolder text-dark text-center mb-3"
      >
        No data
      </div>
      <!--begin::Table-->
      <div v-if="count" class="table-responsive">
        <table class="table table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="p-0" style="min-width: 200px">Name</th>
              <th class="p-0" style="min-width: 200px">Expression</th>
              <th class="p-0 cell--sort">
                <span
                  class="d-flex align-items-center"
                  @click.self="onSorted('created_at')"
                  >Created at
                  <span
                    v-if="sorted_fields.created_at"
                    :class="'order_sorted'"
                    >{{ sorted_fields.created_at.ordering }}</span
                  >
                  <span
                    class="svg-icon svg-icon-md svg-icon-danger clear_sorted"
                    v-if="sorted_fields.created_at"
                    title="Remove from sorting"
                    @click="clearSorted('created_at')"
                  >
                    <inline-svg
                      :transformSource="transform"
                      src="public-media/svg/icons/Navigation/Close.svg"
                    ></inline-svg>
                  </span>
                  <span
                    :class="`svg-icon svg-icon-md svg-icon-primary sorted_icon ${
                      sorted_fields.created_at ? '' : 'd-none'
                    }`"
                  >
                    <inline-svg
                      :transformSource="transform"
                      :src="`public-media/svg/icons/Navigation/${buildSortedIconClass(
                        'created_at'
                      )}.svg`"
                    ></inline-svg>
                  </span>
                </span>
              </th>
              <th class="p-0" style="min-width: 100px">Country</th>
              <th class="p-0" style="min-width: 80px">Active</th>
              <th class="p-0" style="min-width: 80px"></th>
            </tr>
          </thead>
          <tbody v-if="list">
            <template v-for="(item, i) in list">
              <tr v-bind:key="i">
                <td class="pl-0">
                  {{ item.name }}
                  <div v-if="item.settings && item.settings.tags">
                    <span
                      v-for="tag in item.settings.tags"
                      :key="`${item.id}${tag}`"
                      class="label label-lg label-light-primary label-inline font-weight-bold mr-1 mb-1"
                      >{{ tag }}</span
                    >
                  </div>
                </td>
                <td class="pl-0">
                  {{ item.expression }}
                </td>
                <td class="pl-0">
                  {{ renderDate(item.created_at) }}
                </td>
                <td class="pl-0">
                  {{ item.country_code ? item.country_code : "Any country" }}
                </td>
                <td>
                  <b-form-checkbox
                    name="check-button"
                    switch
                    size="lg"
                    v-model="item.is_active"
                    @change="changeActive(item.id, item.is_active)"
                  >
                  </b-form-checkbox>
                </td>
                <td class="text-right pr-0">
                  <button
                    class="btn btn-icon btn-light btn-sm"
                    @click="onOpenEdit(item)"
                    v-b-tooltip.hover.left="'Settings'"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg
                        :transformSource="transform"
                        src="public-media/svg/icons/General/Settings-1.svg"
                      />
                    </span>
                  </button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <pagination :count="count" />

    <rules-settings
      :show="show"
      :onHide="onHide"
      :onSubmit="onSubmit"
      :isLoading="false"
      :url="ruleName"
      :defaultSettings="ruleSettings"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import * as TYPES from "@/core/services/store/modules/rules/types";
import * as PAGE_TYPES from "@/core/services/store/modules/page/types";

import { renderDate } from "@/core/services/services";
import RulesSettings from "./RulesSettings";
import Pagination from "@/view/content/Pagination";
import { editRules } from "../../../../core/services/store/modules/rules/api";
import { requestStatus } from "@/core/services/services";
export default {
  name: "DomainsTable",
  props: { list: Array, count: Number, status: Object, title: String },
  components: { RulesSettings, Pagination },
  data: () => ({
    show: false,
    editRule: null,
    sorted_field: "",
    sorted_fields: {},
  }),
  computed: {
    ...mapState({
      filter: (state) => state.page.filter,
      ordering: (state) =>
        state.page.filter ? state.page.filter.ordering : "",
    }),
    ruleSettings: function () {
      return this.editRule ? this.editRule : null;
    },
    ruleName: function () {
      return this.editRule ? this.editRule.name : "";
    },
    listName: function () {
      return this.editDomain
        ? this.editDomain.is_whitelisted
          ? "Blacklist"
          : "Whitelist"
        : "";
    },
    renderDate: () => renderDate,
  },
  methods: {
    ...mapActions({
      loadDataAction: `rules/${TYPES.GET_RULES}`,
      oneditDomain: `rules/${TYPES.EDIT_RULE}`,
    }),
    ...mapMutations({
      changeFilter: `page/${PAGE_TYPES.CHANGE_FILTER}`,
    }),
    changeActive(id, value) {
      editRules(id, { is_active: value }).then((response) => {
        if (response.status === requestStatus.success) {
          this.$toaster.success("Changes saved");
        } else {
          this.$toaster.error("An error occurred");
        }
      });
    },
    onHide: function () {
      this.show = false;
      this.editRule = null;
    },
    onSubmit: function (data) {
      this.show = false;
      this.oneditDomain({
        id: this.editRule.id,
        data,
      });
      this.editRule = null;
    },
    onOpenMultipleEdit: function () {
      this.editRule = {};
      this.show = true;
    },
    onOpenEdit: function (domain) {
      this.editRule = domain;
      this.show = true;
    },
    onFilter() {
      let ordering_string = "";
      for (let key in this.sorted_fields) {
        if (this.sorted_fields.hasOwnProperty(key)) {
          ordering_string = `${ordering_string}${ordering_string ? "," : ""}${
            this.sorted_fields[key].value === "asc" ? "" : "-"
          }${key}`;
        }
      }
      this.changeFilter({ ordering: ordering_string });
    },
    onSorted: function (field) {
      if (
        !this.sorted_fields[field] ||
        this.sorted_fields[field].value === "desc"
      ) {
        this.sorted_fields[field] = { value: "asc" };
      } else {
        this.sorted_fields[field] = { value: "desc" };
      }
      this.onFilter();
    },
    clearSorted: function (field) {
      if (this.sorted_fields[field]) {
        delete this.sorted_fields[field];
        this.onFilter();
      }
    },
    buildSortedCellClass: function (field) {
      let cell_name = field.substring(
        0,
        field.indexOf("_id") > 0 ? field.indexOf("_id") : field.length
      );
      return `kt-datatable__cell ${"kt-datatable__cell--sort"} ${cell_name} ${
        this.sorted_fields[field] ? " kt-datatable__cell--sorted" : ""
      }`;
    },
    buildSortedIconClass: function (field) {
      return this.sorted_fields[field] &&
        this.sorted_fields[field].value === "asc"
        ? "Arrow-down"
        : "Arrow-up";
    },
    setOrdering: function (ordering) {
      this.sorted_fields = {};
      let ordering_array = ordering.split(",");
      let index = 1;
      ordering_array.forEach((item) => {
        if (item[0] === "-") {
          this.sorted_fields[item.substring(1)] = {
            value: "desc",
            ordering: index,
          };
        } else {
          this.sorted_fields[item] = {
            value: "asc",
            ordering: index,
          };
        }
        index++;
      });
    },
    transform: function (svg) {
      let title = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "title"
      );
      title.textContent = "";

      const oldTitle = svg.getElementsByTagName("title")[0];
      if (oldTitle) {
        svg.replaceChild(title, oldTitle);
      }

      return svg;
    },
  },
  watch: {
    ordering(newValue) {
      this.setOrdering(newValue);
    },
  },
  created: function () {
    if (this.ordering) {
      this.setOrdering(this.ordering);
    }
  },
};
</script>

<style>
.table-borderless thead {
  height: 24px;
}
</style>
