<template>
  <div v-if="count > limit" class="card-footer">
    <div
      class="kt-datatable__pager kt-datatable--paging-loaded redirect-pagination"
    >
      <div class="kt-datatable__pager-nav">
        <div>
          <b-pagination
            v-model="currentPage"
            :per-page="limit"
            :total-rows="count"
          >
            <span slot="first-text" class="kt-datatable__pager-link--first"
              ><i class="flaticon2-fast-back"
            /></span>
            <span slot="prev-text" class="kt-datatable__pager-link--prev"
              ><i class="flaticon2-back"
            /></span>
            <span slot="next-text" class="kt-datatable__pager-link--next"
              ><i class="flaticon2-next"
            /></span>
            <span slot="last-text" class="kt-datatable__pager-link--last"
              ><i class="flaticon2-fast-next"
            /></span>
            <span slot="ellipsis-text" class="kt-datatable__pager-link--last"
              >...</span
            >
            <span slot="page" slot-scope="{ page }">{{ page }}</span>
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import * as TYPES from "@/core/services/store/modules/page/types";
import { calculatePagesCount } from "@/core/services/services";

export default {
  name: "Pagination",
  props: {
    count: Number,
  },
  data: () => ({
    currentPage: 1,
  }),
  computed: {
    ...mapState({
      limit: (state) => (state.page.filter ? state.page.filter.limit : 0),
      offset: (state) => (state.page.filter ? state.page.filter.offset : 0),
    }),
  },
  methods: {
    ...mapMutations({
      changeFilter: `page/${TYPES.CHANGE_FILTER}`,
    }),
    changePage: function (newValue) {
      if (newValue !== calculatePagesCount(this.offset, this.limit) + 1) {
        this.changeFilter({ offset: (newValue - 1) * this.limit });
      }
    },
  },
  watch: {
    currentPage(newValue) {
      if (newValue !== calculatePagesCount(this.offset, this.limit) + 1) {
        this.changeFilter({ offset: (newValue - 1) * this.limit });
      }
    },
    offset(newValue) {
      let currentPage = calculatePagesCount(newValue, this.limit) + 1;
      if (currentPage !== this.currentPage) {
        this.currentPage = currentPage;
      }
    },
  },
  mounted: function () {
    this.currentPage = calculatePagesCount(this.offset, this.limit) + 1;
  },
  created: function () {
    this.currentPage = calculatePagesCount(this.offset, this.limit) + 1;
  },
};
</script>

<style scoped></style>
