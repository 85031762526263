<template>
  <b-modal
    ref="modal"
    :title="`Settings ${url ? 'for ' + url : ''}`"
    @ok="onSaveClick"
    @hidden="onHide"
    @cancel="onHide"
    ok-variant="success"
    :okDisabled="isLoading"
  >
    <template slot="modal-ok"> Save</template>
    <form v-on:submit="onSave" class="kt-form kt-form--label-right">
      <div class="form-group row">
        <label class="col-xl-4 col-lg-3 col-form-label"
          >Select domain for copy settings</label
        >
        <div class="col-lg-9 col-xl-6 redirect-select">
          <v-select
            :options="list"
            :getOptionLabel="(option) => option.domain"
            placeholder="Select domain"
            :value="settingsForCopy"
            @input="
              (newValue) => {
                settingsForCopy = newValue;
              }
            "
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                v-bind="attributes"
                v-on="events"
                @input="(e) => onChangeSearchDomain(e)"
                v-model="search_domains"
              />
            </template>
          </v-select>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-4 col-lg-3 col-form-label">Name*</label>
        <div class="col-lg-9 col-xl-6">
          <input class="form-control" v-model="name" required />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-4 col-lg-3 col-form-label">Expression*</label>
        <div class="col-lg-9 col-xl-6">
          <input class="form-control" v-model="expression" required />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-4 col-lg-3 col-form-label">Country</label>
        <div class="col-lg-9 col-xl-6">
          <input class="form-control" v-model="country" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-4 col-lg-3 col-form-label">Redirect URL*</label>
        <div class="col-lg-9 col-xl-6">
          <input class="form-control" v-model="redirect_url" required />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xl-4 col-lg-3 col-form-label">Type</label>
        <div class="col-lg-9 col-xl-6 redirect-select">
          <v-select
            :options="flowOptions"
            :clearable="false"
            placeholder="Select flow"
            :value="redirect_flow"
            :filterable="false"
            :searchable="false"
            @input="
              (newValue) => {
                redirect_flow = newValue;
              }
            "
          >
          </v-select>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-4 col-lg-3 col-form-label"
          >Redirect Percent*</label
        >
        <div class="col-lg-9 col-xl-6">
          <input
            class="form-control"
            v-model="redirect_percent"
            min="0"
            max="100"
            type="number"
            required
          />
        </div>
      </div>

      <div v-if="redirect_flow === 'delay'" class="form-group row">
        <label class="col-xl-4 col-lg-3 col-form-label">Delay*</label>
        <div class="col-lg-9 col-xl-6">
          <input class="form-control" v-model="redirect_delay" required />
        </div>
      </div>

      <div v-if="redirect_flow === 'scroll'" class="form-group row">
        <label class="col-xl-4 col-lg-3 col-form-label"
          >Scroll percentage*</label
        >
        <div class="col-lg-9 col-xl-6">
          <input class="form-control" v-model="scroll_percentage" required />
        </div>
      </div>

      <div v-if="redirect_flow === 'click'" class="form-group row">
        <label class="col-xl-4 col-lg-3 col-form-label">Selector*</label>
        <div class="col-lg-9 col-xl-6">
          <input class="form-control" v-model="click_selector" required />
        </div>
      </div>
      <div class="form-group row mb-0">
        <label class="col-xl-4 col-lg-3 col-form-label">Tags</label>
        <div class="col-lg-9 col-xl-6">
          <tags ref="tags" :tags="tags" />
        </div>
      </div>

      <button ref="ModalSubmit" type="submit" class="kt-hidden d-none" />
    </form>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import { debounce } from "@/core/services/services";
import { mapActions, mapState } from "vuex";
import * as TYPES from "@/core/services/store/modules/domains/types";
import Tags from "../../../content/Tags";

export default {
  name: "RulesSettings",
  components: { vSelect, Tags },
  props: {
    url: String,
    isLoading: Boolean,
    show: Boolean,
    onHide: Function,
    onSubmit: Function,
    defaultSettings: Object,
  },
  data: () => ({
    redirect_url: "",
    name: "",
    country: "",
    expression: "",
    redirect_delay: 1000,
    scroll_percentage: 15,
    click_selector: "a.survey_button",
    redirect_flow: "delay",
    redirect_percent: 0,
    settingsForCopy: null,
    search_domains: "",
    tags: [],
  }),
  computed: {
    ...mapState({
      list: (state) => state.domains.domains_list_for_settings,
    }),
    flowOptions: () => ["delay", "scroll", "click"],
  },
  methods: {
    ...mapActions({
      loadData: `domains/${TYPES.GET_DOMAINS_FOR_SETTINGS}`,
    }),
    onSaveClick: function (e) {
      e.preventDefault();
      this.$refs.ModalSubmit.click();
    },
    onSave: function (e) {
      e.preventDefault();
      if (this.country && this.country.length > 2) {
        this.$toaster.warning("Country field has no more than 2 characters.");
        return;
      }

      const urlPattern = /^(http|https):\/\/[^ "]+$/;

      if (!urlPattern.test(this.redirect_url)) {
        this.$toaster.warning("Incorect redirect url.");
        return;
      }

      const data = {
        country_code: this.country ? this.country : null,
        name: this.name,
        expression: this.expression,
        settings: {
          redirect_url: this.redirect_url,
          redirect_flow: this.redirect_flow,
          redirect_percent: this.redirect_percent,
          tags: this.$refs.tags.getTagsList(),
        },
      };
      switch (this.redirect_flow) {
        case "delay":
          data.settings.redirect_delay = this.redirect_delay;
          break;
        case "scroll":
          data.settings.scroll_percentage = this.scroll_percentage;
          break;
        case "click":
          data.settings.click_selector = this.click_selector;
          break;
      }
      this.onSubmit(data);
    },
    onOpen: function () {
      this.$refs["modal"].show();
    },
    onCancel: function () {
      this.$refs["modal"].hide();
      this.redirect_url = "";
      this.name = "";
      this.country = "";
      this.expression = "";
      this.redirect_delay = 1000;
      this.scroll_percentage = 15;
      this.click_selector = "a.survey_button";
      this.redirect_flow = "delay";
      this.redirect_percent = 0;
      this.settingsForCopy = null;
      this.search_domains = "";
      this.tags = [];
    },
    onChangeSearchDomain: function (e) {
      e.preventDefault();
      this.onWaitChange();
    },
    onWaitChange: function () {
      if (this.search_domains.length >= 2 || this.search_domains === "") {
        this.loadData(`domain=${this.search_domains}`);
      }
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.onOpen();
      } else {
        this.onCancel();
      }
    },
    defaultSettings(newValue) {
      if (newValue) {
        if (newValue.expression) this.expression = newValue.expression;
        if (newValue.country_code) this.country = newValue.country_code;
        if (newValue.name) this.name = newValue.name;
      }
      if (newValue && newValue.settings) {
        this.redirect_url = newValue.settings.redirect_url;
        this.redirect_delay = newValue.settings.redirect_delay;
        this.scroll_percentage = newValue.settings.scroll_percentage;
        this.click_selector = newValue.settings.click_selector;
        this.redirect_flow = newValue.settings.redirect_flow;
        this.redirect_percent = newValue.settings.redirect_percent;
        this.tags = newValue.settings.tags || [];
      }
    },
    settingsForCopy(newValue) {
      this.search_domains = "";
      if (newValue) {
        if (newValue.country_code) this.country = newValue.country_code;
      }
      if (newValue && newValue.settings) {
        this.redirect_url = newValue.settings.redirect_url;
        this.redirect_delay = newValue.settings.redirect_delay;
        this.scroll_percentage = newValue.settings.scroll_percentage;
        this.click_selector = newValue.settings.click_selector;
        this.redirect_flow = newValue.settings.redirect_flow;
        this.redirect_percent = newValue.settings.redirect_percent;
        this.tags = newValue.settings.tags || [];
      }
    },
  },
  created: function () {
    this.onWaitChange = debounce(this.onWaitChange, 500);
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
