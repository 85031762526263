<template>
  <div class="row">
    <!-- <div class="col">
      <form
        @submit="onSubmitVisitsForm"
        class="filter-item"
        id="kt_subheader_visits_form"
      >
        <div class="form-group">
          <input
            v-model.trim="visits"
            type="number"
            class="form-control"
            placeholder="Visits"
            @input="onChangeVisits"
            id="visits"
          />
        </div>
      </form>
    </div> -->

    <!-- <div class="col">
      <form
        @submit="onSubmitSearchForm"
        class="filter-item"
        id="kt_subheader_search_form"
      >
        <div class="form-group">
          <div class="input-icon">
            <input
              v-model.trim="search"
              type="text"
              class="form-control"
              placeholder="Search..."
              @input="onChangeSearch"
              id="generalSearch"
            />
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="public-media/svg/icons/General/Search.svg"></inline-svg>
            </span>
          </div>
        </div>
      </form>
    </div> -->
    <CreateRule />

    <!-- <div class="col">
      <div class="redirect-select filter-item">
        <v-select
          :options="filterOptions"
          :clearable="true"
          placeholder="Select list"
          :getOptionLabel="(option) => option.label"
          :reduce="(option) => `${option.value}`"
          @input="(value) => setSelectedFilter('is_whitelisted', value)"
          :value="filter_selected"
          :searchable="false"
        />
      </div>
    </div> -->

    <!-- <div class="col">
      <div class="redirect-select filter-item">
        <v-select
          :options="enabledOptions"
          :clearable="true"
          placeholder="Filter by enabled"
          :getOptionLabel="(option) => option.label"
          :reduce="(option) => `${option.value}`"
          @input="(value) => setSelectedFilter('enabled', value)"
          :value="enabled_selected"
          :searchable="false"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import * as types from "@/core/services/store/modules/page/types";
// import vSelect from "vue-select";
import { debounce } from "@/core/services/services";
import CreateRule from "./CreateRule.vue";
export default {
  name: "RulesFilter",
  components: { CreateRule },
  data: () => ({
    filter_selected: "",
    enabled_selected: "",
    search: "",
    visits: "",
  }),
  computed: {
    ...mapState({
      search_field: (state) =>
        state.page.filter ? state.page.filter.name : "",
    }),
    filterOptions: () => [
      { label: "Whitelist", value: "true" },
      { label: "Blacklist", value: "false" },
    ],
    enabledOptions: () => [
      { label: "Enabled", value: "true" },
      { label: "Disabled", value: "false" },
    ],
  },
  methods: {
    ...mapMutations({
      changeFilter: `page/${types.CHANGE_FILTER}`,
    }),
    setSelectedFilter: function (field, newValue) {
      if (newValue === null) {
        newValue = "";
      }
      this.changeFilter({ [field]: newValue });
    },
    onChangeSearch: function () {
      this.onWaitChange();
    },
    onSubmitSearchForm: function (e) {
      e.preventDefault();
      this.changeFilter({ name: this.search });
    },
    onWaitChange: function () {
      if (this.search.length >= 2 || this.search === "") {
        this.changeFilter({ name: this.search });
      }
    },
    onChangeVisits: function () {
      this.onWaitChangeVisits();
    },
    onSubmitVisitsForm: function (e) {
      e.preventDefault();
      this.changeFilter({ visits: this.visits });
    },
    onWaitChangeVisits: function () {
      this.changeFilter({ visits: this.visits });
    },
  },
  watch: {
    search_field(newValue) {
      this.search = newValue;
    },
  },
  created: function () {
    this.onWaitChange = debounce(this.onWaitChange, 500);
    this.onWaitChangeVisits = debounce(this.onWaitChangeVisits, 500);
    this.search = this.search_field;
  },
};
</script>

<style scoped></style>
